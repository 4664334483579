<script setup lang="ts">
const {
    heading = '',
    backdrop = 'dark',
    showActionPrimary = true,
    labelActionPrimary = '',
    isDisabledActionPrimary = false,
    showActionSecondary = false,
    labelActionSecondary = '',
    isDisabledActionSecondary = false,
    classInner = '',
    isDesktopCentered = true,
    autofocusPrimary = false,
    autofocusSecondary = false,
} = defineProps<{
    heading?: string;
    backdrop?: 'dark' | 'light';
    showActionPrimary?: boolean;
    labelActionPrimary?: string;
    isDisabledActionPrimary?: boolean;
    showActionSecondary?: boolean;
    labelActionSecondary?: string;
    isDisabledActionSecondary?: boolean;
    classInner?: string;
    isDesktopCentered?: boolean;
    autofocusPrimary?: boolean;
    autofocusSecondary?: boolean;
}>();

defineEmits(['callbackPrimary', 'callbackSecondary', 'closed']);

const elDialog = useTemplateRef('elDialog');

/**
 *
 */
defineExpose({
    open: () => elDialog.value?.open(),
    close: () => elDialog.value?.close(),
    animateNextItem: (callback: () => void) => elDialog.value?.animateNextItem(callback),
});
</script>

<template>
    <BaseDialog
        ref="elDialog"
        :backdrop="backdrop"
        @closed="$emit('closed')"
    >
        <div
            class="flex h-full items-start justify-center px-5 py-6 sm:px-container-gap sm:py-10"
            :class="isDesktopCentered ? 'sm:items-center' : ''"
        >
            <div
                class="w-full max-w-[--w-max-dialog]"
                :class="classInner"
            >
                <div
                    data-transition-next
                    data-transition-slide
                    class="relative z-[11] rounded bg-white"
                >
                    <BaseCloseButton class="absolute right-2 top-2" @click="elDialog?.close" />

                    <slot name="header">
                        <div class="flex items-center justify-between border-b border-dashed border-shade-2 px-5 pb-3 pt-5">
                            <h3 class="pr-2 typo-heading-sm">
                                {{ heading }}
                            </h3>
                        </div>
                    </slot>
                    <div class="p-5">
                        <slot />
                    </div>
                </div>

                <div
                    class="
                        mt-6
                        flex
                        items-center
                        justify-center
                    "
                >
                    <slot name="content_below">
                        <div
                            class="z-10 flex min-w-0 space-x-4"
                            data-transition-fade
                            data-transition-next
                        >
                            <BaseButton
                                v-if="showActionSecondary"
                                :disabled-visually="isDisabledActionSecondary"
                                color-scheme="secondary-on-dark"
                                :autofocus="autofocusSecondary"
                                @click="$emit('callbackSecondary')"
                            >
                                {{ labelActionSecondary }}
                            </BaseButton>

                            <BaseButton
                                v-if="showActionPrimary"
                                :disabled-visually="isDisabledActionPrimary"
                                color-scheme="primary-on-dark"
                                class="shrink-0"
                                :autofocus="autofocusPrimary"
                                @click="$emit('callbackPrimary')"
                            >
                                {{ labelActionPrimary }}
                            </BaseButton>
                        </div>
                    </slot>
                </div>
            </div>
        </div>

        <slot name="outside_card" />
    </BaseDialog>
</template>
